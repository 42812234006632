<!-- 首页 -->
<template>
  <div class="index">
    <!-- 地图 -->
    <div id="container"></div>
    <!-- 左侧手绘地图开关 -->
    <div class="leftSwitch">
      <div class="switch">
        <mt-switch v-model="value" @input="onLelayerInput"></mt-switch>
      </div>
      <p class="leftTitel">{{ this.lanData.tagName3 }}</p>
    </div>
    <!-- 地点选择 -->
    <div class="placeSelect">
      <div class="places" v-for="(item, index) in initData" :key="index">
        <div class="placeUrl" @click="changePlace(index)">
          <img
            :src="
              tabActive == index
                ? $global.ImgUrl + item.listIcon
                : $global.ImgUrl + item.listIcon
            "
            alt=""
          />
        </div>
        <div :class="changeMark == index ? 'placeNames' : 'placeName'">
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 右边选择 -->
    <div class="rightSelect">
      <router-link to="/index" class="rightContent">
        <div class="rightIcon">
          <img src="./image/icon_home.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName7 }}</p>
      </router-link>
      <div class="rightContent">
        <div class="rightIcon" @click="showSearchPageFn()">
          <img src="./image/icon_search.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName8 }}</p>
      </div>
      <div class="rightContent" @click="pathShowFn()">
        <div class="rightIcon">
          <img src="./image/icon_route.svg" alt="" />
        </div>
        <p class="rightTitel">{{ this.lanData.tagName9 }}</p>
      </div>
    </div>
    <!-- 右侧语言选择 -->
    <div class="rightSelected">
      <div class="allShow">
        <div class="selectLanguage">
          <div class="selectIcon" @click="changeLanguage()"></div>
        </div>
      </div>
      <div class="languageSelect" v-show="languageTagData">
        <div
          :class="[
            'selectContent',
            languageNum == index ? 'selectContent-active' : '',
          ]"
          v-for="(item, index) in languageData"
          :key="index"
          @click="languageNumFn(index, item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <!-- 路线 -->
    <div class="path" v-show="pathShow">
      <div class="pathContent">
        <div class="photoTitle"></div>
        <div class="closePath" @click="pathShow = false"></div>
        <div class="lineContent">
          <div
            @click="navigationShowFn(index)"
            v-for="(item, index) in newlines"
            :key="index"
          >
            <div class="contentTitle">
              <span>{{ item.name }}</span>
              <div class="tox"></div>
            </div>
            <div class="contentLine">
              <div class="lineTitle">
                共计{{ item.pointNum }}个景区 | 预计{{ item.duration }}小时
              </div>
              <div class="line">
                <div
                  class="detailLine"
                  v-for="(items, index) in item.linePointVos"
                  :key="index"
                >
                  <div class="linePoint">
                    {{ items.pointName }}
                    <span class="to" v-if="index + 1 < item.pointNum"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 路线导航 -->
    <div class="lineNavigation" v-show="navigationShow">
      <div class="navigationTab">
        <div class="closeNavigation" @click="navigationFn()"></div>
        <div
          :class="['tabName', tabNameNum == index ? 'tabNameActive' : '']"
          v-for="(item, index) in newlines"
          :key="index"
          @click="tabNameFn(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 详情展示内容 -->
      <div class="navigationWindow">
        <div
          style="width: 100%; height: 7.8rem"
          v-for="(item, index) in markerDetailData"
          :key="index"
        >
          <div class="windowImg" @click="audioPlay()">
            <img :src="$global.ImgUrl + item.cover" alt="" />
            <div class="icon_audio" v-if="!playing"></div>
            <div class="icon_audio-active" v-else></div>
          </div>
          <div class="windowContent">
            <div class="windowTitle">{{ item.name }}</div>
            <div class="WindowBtn">
              <div class="navigationBtn1" @click="toNavigationFn(item)">
                导航
              </div>
              <div class="navigationBtn2" @click="showDetialpage(item.id)">
                详情
              </div>
            </div>
          </div>
        </div>
        <div class="line-bottom">
          <div class="line-pois" id="linePois">
            <div
              v-for="(item, index) in lineMarkerArrDatas"
              :key="item.id"
              class="line-poi-item"
              v-show="item.pointId"
            >
              <div @click="allLinePointFn(index, item.pointId)">
                <div
                  :class="[
                    'line-item-index',
                    allLinePointNum == index ? 'line-item-index-active' : '',
                  ]"
                >
                  {{ index + 1 }}
                </div>
                <div
                  :class="[
                    'line-item-name',
                    allLinePointNum == index ? 'line-item-name-active' : '',
                  ]"
                >
                  {{ item.pointName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索页面 -->
    <div class="search" v-show="showSearchPage">
      <!-- 搜索框 -->
      <div class="search-box">
        <input v-model="keyword" type="text" />
        <div class="serchbtn" @click="onSearch()"></div>
        <span class="onCancle" @click="onCancle()">{{
          this.lanData.tagName6
        }}</span>
      </div>
      <!-- 搜索内容 -->
      <div class="searchContent" v-show="searchShow">
        <div
          class="sContent"
          v-for="(item, index) in this.searchData"
          :key="index"
        >
          <div class="scenicItem" @click="backPrev(item.id)">
            <div class="leftItem">
              <div class="itemIcon">
                <img
                  :src="$global.ImgUrl + item.webPoiTypeVos[0].listIcon"
                  alt=""
                />
              </div>
              <div class="itemName">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="noResult" v-show="noResultShow">
        {{ this.lanData.tagName4 }}
      </div>
      <!-- 历史框 -->
      <div v-show="histroyContentShow">
        <div class="histroy">
          <div class="leftName">{{ this.lanData.tagName5 }}</div>
          <div class="icon_del" @click="clearHistroy"></div>
        </div>
        <!-- 历史记录内容 -->
        <div class="histroyContent">
          <div
            class="histroyList"
            v-for="(item, index) in searchList"
            :key="index"
            @click="goSearchDetail(item)"
          >
            <span class="areaName">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 详情页面 -->
    <div class="visitorsDetail" v-show="showDetailPage">
      <div v-for="(item, index) in markerDetailData" :key="index">
        <!-- 景点介绍卡片 -->
        <div class="introduceCard">
          <div class="swiper">
            <mt-swipe :auto="4000">
              <mt-swipe-item v-for="(items, index) in item.imgVos" :key="index">
                <img
                  style="width: 100%; height: 100%"
                  :src="$global.ImgUrl + items.pic"
                  alt=""
                />
              </mt-swipe-item>
            </mt-swipe>
            <div class="vr" v-if="item.allview" @click="toVr(item.allview)">
              <div class="icoon">
                <img src="./image/icon_vr.svg" alt="" />
              </div>
              VR
            </div>
          </div>
          <div class="introduceText">
            <div class="introduceTitle">
              <div class="titleName">{{ item.name }}</div>
            </div>
            <div class="introduceAddress">
              <span class="icon-Address"></span>
              <span class="addressText">{{ item.address }}</span>
            </div>
            <div class="introduceBtn" @click="toNavigationFn(item)">
              <div class="hangBtn"></div>
            </div>
          </div>
        </div>
        <!-- 中间介绍 -->
        <div class="centerIntroduce">
          <div class="intro" v-show="item.audio">
            <div class="audio">
              <div class="audio-box">
                <audio
                  :src="$global.ImgUrl + item.audio"
                  id="audioPlayer"
                ></audio>
                <!-- 音频播放模块 -->
                <div class="player-box">
                  <div
                    class="audio-left flex center"
                    id="audioPlayer"
                    @click="audioPlay"
                  >
                    <a v-if="!playing">
                      <img src="./image/icon_play(1).svg" alt="" />
                    </a>
                    <a v-else>
                      <img src="./image/push.svg" alt="" />
                    </a>
                  </div>
                  <div class="audio-right flex center column">
                    <!-- <p style="max-width: 536px;">Beta-B_Kan R. Gao.mp3</p> -->
                    <div
                      class="progress-bar-bg"
                      id="progressBarBg"
                      @click="progressBarBg"
                    >
                      <span
                        id="progressDot"
                        :style="'left:' + dotLeft + '%'"
                      ></span>
                      <div
                        class="progress-bar"
                        id="progressBar"
                        :style="'width:' + barWidth + '%'"
                      ></div>
                    </div>
                    <div class="audio-time">
                      <span class="audio-length-current" id="audioCurTime">{{
                        audioCurTime
                      }}</span
                      >/
                      <span class="audio-length-total">{{ duration }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="introText" v-html="item.pointInfo"></div>
        </div>
      </div>
      <!-- 底部返回 -->
      <div @click="showDetailPageFn()" class="buttomBack"></div>
    </div>
    <div class="leftLocation" @click="location()">
      <img src="./image/icon_location.svg" alt="" />
    </div>
    <!-- 信息窗体组件 -->
    <infoWindow
      ref="infoWindow"
      v-show="activePoiData"
      :extData="activePoiData"
      @infoClose="qureyData"
      @showDetail="showDetialpage"
    ></infoWindow>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Vue from "vue";
import { Switch, Indicator, MessageBox, Swipe, SwipeItem } from "mint-ui";
import infoWindow from "../../components/infoWindow/infoWindow.vue";
import wx from "../../assets/js/wx";
import {
  allLineApi,
  scenicDataApi,
  markerDetailApi,
  searchPoiApi,
  languageDataApi,
  lineEyeApi,
} from "../../request/api/index";
Vue.component(
  Switch.name,
  Switch,
  Swipe.name,
  Swipe,
  SwipeItem.name,
  SwipeItem
);
const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    infoWindow,
  },
  data() {
    //这里存放数据
    return {
      mapObj: null, //初始化地图
      languageType: 1, //语言种类
      scenicId: null, //景区id
      scenicData: [], //相应的景区数据
      markers: [], //创建标记点
      // markerArr: [], //存放点位
      initData: [], //初始数据
      markerArr: [], //初始化点位
      markerArrIcon: [], //初始化点位图标
      checked: true, //手绘地图显示
      tabActive: 0, //底部导航切换
      activeMarker: null, //当前被点击的点位
      pathShow: false, //路线显示
      lineMarkerArrData: null, //线路总数据
      allLineData: [], //全域路线数据
      allLineSelectData: [], //全域路线数据细分
      allLinePointNum: -1,
      languageType: 1, //首页语言选择的初始id
      languageData: [], //语言数据
      languageNum: 0, //语言选择的初始下标
      polyline: null, //路线的全局变量
      value: true, //switch的初始值
      tabNameNum: 0,
      navigationShow: false, //关闭路线导航
      changeMark: 0, //地点切换的初始数据
      infoWindow: null, //信息窗体
      activePoiData: "", //选中点位的数据
      pid: null, //marker的id
      markerDetailData: [], //点位详情数据
      cluster: null, //点聚合集合
      keyword: "", //搜索框输入的内容
      searchList: [], //搜索历史列表
      searchData: [], //搜索的数据
      searchShow: false, //显示搜索的内容
      histroyContentShow: true, //历史记录的显示
      noResultShow: false, //搜索不到结果
      tagData: null, //标签数据
      iconShow: true, //显示历史记录的图标
      showSearchPage: false, //搜索页面的显示
      showDetailPage: false, //详情页面的显示
      // 音频播放
      duration: "00:00", //音频总时长
      audioCurTime: "00:00", //音频当前时间
      playing: false, //音频播放中的状态
      barWidth: 0, //已播放条的宽度值
      dotLeft: 0, //进度点的left值
      activeMarker: null, //线路当前点击的点位
      resLocation: [], //自身点位的坐标
      newlines: null,
      lineMarkerArrDatas: null,
      lanData: [],
      lineId: "",
      lat: "",
      lng: "",
      languageTagData: false, //切换语言
      manyLanData: [
        {
          tagName: "详情",
          tagName1: "导览",
          tagName2: "导航",
          tagName3: "手绘",
          tagName4: " 查不到相关数据，换个词试试~",
          tagName5: "历史记录",
          tagName6: "取消",
          tagName7: "主页",
          tagName8: "搜索",
          tagName9: "路线",
        },
        {
          tagName: "Detail",
          tagName1: "Tour",
          tagName2: "Navigation",
          tagName3: "HandPainted",
          tagName4: "Can't find relevant data, try another word ~",
          tagName5: "The historical record",
          tagName6: "Cancel",
          tagName7: "Index",
          tagName8: "Search",
          tagName9: "Line",
        },
        {
          tagName: "자세한정보",
          tagName1: "깜짝놀",
          tagName2: "네비게이션",
          tagName3: "손으로",
          tagName4: "관련 수치를 찾을 수 없으니 단어를 바꾸어 보자",
          tagName5: "역사 기록",
          tagName6: "취소",
          tagName7: "홈페이지",
          tagName8: "검색",
          tagName9: "노선",
        },
        {
          tagName: "详细",
          tagName1: "ガイドブック",
          tagName2: "カーナビ",
          tagName3: "(もうしで",
          tagName4: "データが出てこないから、言葉を変えてみる~",
          tagName5: "歴史の記録。",
          tagName6: "キャンセル",
          tagName7: "ホームページ",
          tagName8: "検索",
          tagName9: "回線",
        },
      ],
      wanPianList: [
        {
          id: 35,
          name: "新昌县十九峰景区",
          num: 11398,
        },
        {
          id: 29,
          name: "东湖景区",
          num: 42850,
        },
        {
          id: 39,
          name: "绍兴镜湖湿地公园",
          num: 57861,
        },
        {
          id: 30,
          name: "大禹陵景区",
          num: 11396,
        },
        {
          id: 32,
          name: "杭州湾海上花田景区",
          num: 17884,
        },
        {
          id: 36,
          name: "新昌县丝绸世界旅游区",
          num: 17844,
        },
        {
          id: 44,
          name: "天姥阆苑养生谷",
          num: 57863,
        },
        {
          id: 42,
          name: "十八",
          num: 58012,
        },
        {
          id: 34,
          name: "米果果",
          num: 58013,
        },
        {
          id: 43,
          name: "外婆坑",
          num: 57864,
        },
        {
          id: 33,
          name: "珍珠小镇",
          num: 57865,
        },
        {
          id: 46,
          name: "天烛仙境景区",
          num: 39791,
        },
        {
          id: 40,
          name: "越剧小镇",
          num: 57870,
        },
        {
          id: 38,
          name: "黄酒小镇",
          num: 57869,
        },
      ],
    };
  },
  created() {},
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    keyword() {
      delay(() => {
        const value = this.keyword.trim();
        if (!value) {
          //判断输入框的值是否为空  如果为空 直接返回不在执行
          this.searchShow = false;
          this.histroyContentShow = true;
          this.noResultShow = false;
        } else {
          //如果local里不存在当前输入的关键词 则存到local里
          if (this.searchList.indexOf(value) === -1) {
            this.searchList.unshift(value);
            //历史记录最多只取十个
            this.searchList = this.searchList.slice(0, 10);
            localStorage.setItem("keyword", JSON.stringify(this.searchList));
          }
          this.getSearchPoiData(); //获取搜索的内容
        }
      }, 300);
    },
  },
  //方法集合
  methods: {
    //初始化地图
    initMap() {
      let that = this;
      this.mapObj = new AMap.Map("container", {
        view: new AMap.View2D({
          // 创建地图二维视口
          center: new AMap.LngLat(
            this.scenicData.longitude,
            this.scenicData.latitude
          ),
          zoom: this.scenicData.showZoom, //设置地图初始的的缩放级别
          // zoom: 15,
          rotation: 0, //设置地图旋转角度
        }),
        zooms: [this.scenicData.minZoom, this.scenicData.maxZoom], //地图可缩放的级别
        // zooms: [15, 20],

        lang: "zh_cn", //设置地图语言类型，默认：中文简体
        features: ["bg", "road", "building"], //去除高德的瓦片
      });
      // 瓦片加载
      for (var i in this.wanPianList) {
        if (this.wanPianList[i].id == this.scenicId) {
          // console.log(this.wanPianList[i].num);
          that.leLayer = new AMap.TileLayer({
            // 图块取图地址,加了s=1就是下沙景区限制
            tileUrl: `https://dydl.zysxh5.cn/upload_resource/${this.wanPianList[i].num}/[z]/[x]_[y].png`,
            // tileUrl: "http://192.18.61.250:88/global/57814/[z]/[x]_[y].png",
            zIndex: 100,
          });
        }
      }
      that.leLayer.setMap(this.mapObj);
      // console.log(this.leLayer);
      if (this.navigationShow == false) {
        this.markers = [];
        this.createMarker();
        this.mapObj.add(this.markers);
        // this.addCluster();
      } else if (this.navigationShow == true) {
        this.createLineMarker();
      }
      this.createLocation();
    },
    // 瓦片异步控制开关
    onLelayerInput(checked) {
      this.checked = checked;
      if (checked) {
        this.leLayer.setMap(this.mapObj);
      } else {
        this.leLayer.setMap(null);
      }
    },
    //创建marker点位
    createMarker() {
      let that = this;
      for (var i in this.markerArr) {
        var markIcon, markSelectIcon;
        var hasContent = this.markerArrIcon.hasContent;
        markIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(32, 34),
          // 图标的取图地址
          image: this.$global.ImgUrl + this.markerArrIcon.markIcon,
          // 图标所用图片大小
          imageSize: new AMap.Size(32, 34),
          // 图标取图偏移量
          // imageOffset: new AMap.Pixel(-9, -3),
        });
        markSelectIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(32, 34),
          // 图标的取图地址
          image: this.$global.ImgUrl + this.markerArrIcon.markSelectIcon,
          // 图标所用图片大小
          imageSize: new AMap.Size(32, 34),
          // 图标取图偏移量
          // imageOffset: new AMap.Pixel(-9, -3),
        });
        let marker = new AMap.Marker({
          position: new AMap.LngLat(
            this.markerArr[i].longitude,
            this.markerArr[i].latitude
          ), //marker点的位置
          // content: '<div class="contain">1</div>',
          icon: markIcon,
          offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
          extData: {
            pioData: this.markerArr[i],
            markIcon: markIcon,
            markSelectIcon: markSelectIcon,
            hasContent: hasContent,
          },
        });
        //标记点的文本内容
        marker.setLabel({
          offset: new AMap.Pixel(0, 30), //设置文本标注偏移量
          content: this.markerArr[i].name, //设置文本标注内容
          direction: "center", //设置文本标注方位
        });
        // marker点击监听事件
        AMap.event.addListener(marker, "click", function (e) {
          that.markerClick(this);
        });
        //添加marker到地图
        // this.mapObj.add(marker);
        this.markers.push(marker); //添加marker到地图
      }
    },
    //创建路线marker点位
    createLineMarker() {
      let that = this;
      let arr = this.allLineSelectData.linePointVos;
      this.allLineSelectData.lineMarkerArr = [];
      let arrs = [];
      for (var i in arr) {
        if (arr[i].pointId) {
          // 点标记显示内容，HTML要素字符串
          arrs.push(arr[i]);
          for (var j in arrs) {
            var markerContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain">${parseInt(j) + 1}</div>` +
              "</div>";
            var markerSelectContent =
              "" +
              '<div class="custom-content-marker">' +
              `<div class="contain-active">${parseInt(j) + 1}</div>` +
              "</div>";
          }
          let marker = new AMap.Marker({
            position: new AMap.LngLat(arr[i].longitude, arr[i].latitude), //marker点的位置
            content: markerContent,
            offset: new AMap.Pixel(-20, -50), //marker内容的偏移量
            extData: {
              pioData: arr[i],
              markerContent: markerContent,
              markerSelectContent: markerSelectContent,
            },
          });
          //标记点的文本内容
          marker.setLabel({
            offset: new AMap.Pixel(0, 40), //设置文本标注偏移量
            content: arr[i].pointName, //设置文本标注内容
            direction: "center", //设置文本标注方位
          });
          // marker点击监听事件
          AMap.event.addListener(marker, "click", function (e) {
            that.markerLineClick(this);
          });
          this.allLineSelectData.lineMarkerArr.push(marker);
          this.mapObj.add(marker); //添加marker到地图
        }
      }
    },
    location() {
      let that = this;
      that.wechat.wx.getLocation({
        type: "gcj02", // 默认为 wgs84 返回 gps 坐标，gcj02 返回可用于 wx.openLocation 的坐标
        success: function (res) {
          console.log("获取微信定位成功！");
          that.lat = res.latitude;
          that.lng = res.longitude;
          localStorage.setItem("lat", res.latitude);
          localStorage.setItem("lng", res.longitude);
          if (that.lat) {
            that.getSenicData(); //景区相应数据
          }
        },
      });
      setTimeout(() => {
        that.mapObj.setCenter([that.lng, that.lat]);
      }, 100);
    },
    //创建定位点
    createLocation() {
      let lng1 = localStorage.getItem("lng");
      let lat1 = localStorage.getItem("lat");
      if (lng1) {
        let marker = new AMap.Marker({
          position: new AMap.LngLat(lng1, lat1), //marker点的位置
          content: "<div class='curMarker'></div>",
          offset: new AMap.Pixel(-13, -30), //marker内容的偏移量
        });
        this.mapObj.add(marker);
      }
    },
    //跳转到导航
    toNavigationFn(item) {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信就用微信地图
        this.commonFun.openLocation({
          name: item.name, // 位置名
          latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    // 点位改成 点击之前的样式
    markerSetNomal(marker) {
      if (this.activeMarker) {
        marker.setContent(marker.getExtData().markerContent);
      }
    },
    // 点位改成 点击之后的样式
    markerSetClicked(marker) {
      if (this.activeMarker) {
        marker.setContent(marker.getExtData().markerSelectContent);
      }
    },
    //点击线路的的点标记
    markerLineClick(e) {
      this.mapObj.setCenter(e.getPosition());
      // console.log(e.getExtData().pioData);
      if (this.activeMarker) {
        if (
          e.getExtData().pioData.id == this.activeMarker.getExtData().pioData.id
          // 如果已经有点被点击且 已经被点击的点和这次点击的点相同
        ) {
          return;
        } else {
          this.markerSetNomal(this.activeMarker);
        }
      }
      e.setContent(e.getExtData().markerSelectContent);
      this.activeMarker = e;
      // console.log(this.activeMarker.getExtData().pioData);
      this.pid = e.getExtData().pioData.pointId;
      this.getMarkerDetailData();
      for (var j in this.newlines) {
        for (var i in this.newlines[j].linePointVos) {
          if (
            this.newlines[j].linePointVos[i].pointId ==
            e.getExtData().pioData.pointId
          ) {
            this.allLinePointNum = i;
            // console.log(this.allLinePointNum);
          }
        }
      }
    },
    //创建信息窗体
    createInfoWindow(pio) {
      let that = this;
      this.infoWindow = new AMap.InfoWindow({
        position: [pio.longitude, pio.latitude],
        isCustom: true, //使用自定义窗体
        offset: new AMap.Pixel(0, -42),
        content: that.$refs.infoWindow.$el,
      });
      this.infoWindow.open(this.mapObj);
    },
    // 没有弹窗的信息窗体 生成
    navInfoFun(pio) {
      let that = this;
      that.infoWindow = new AMap.InfoWindow({
        position: [pio.longitude, pio.latitude],
        isCustom: true, //使用自定义窗体
        content: that.createNavInfoWindow(
          pio.name,
          pio.longitude,
          pio.latitude
        ),
        offset: new AMap.Pixel(0, -42),
        retainWhenClose: true,
      });
      that.infoWindow.open(that.mapObj);
      setTimeout(() => {
        // 跳转导航事件
        document.getElementById("goHear").addEventListener(
          "touchstart",
          function () {
            let name = this.getAttribute("data-name");
            let lat = this.getAttribute("data-lat");
            let lng = this.getAttribute("data-lng");
            that.commonFun.openLocation({
              name: name, // 位置名
              latitude: lat, // 纬度，浮点数，范围为90 ~ -90
              longitude: lng, // 经度，浮点数，范围为180 ~ -180。
              address: name, // 地址详情说明
              scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
            });
          },
          false
        );
      }, 300);
    },
    //构建自定义信息窗体
    createNavInfoWindow(name, longitude, latitude) {
      let content =
        '<div class="nav-info"><div class="info-box flex center" id="goHear"  data-name="' +
        name +
        '" data-lng="' +
        latitude +
        '" data-lat="' +
        longitude +
        '"><i class="icon-hear"></i><span>导航</span></div></div>';
      return content;
    },
    //点击marker
    markerClick(e) {
      this.mapObj.setCenter(e.getPosition());
      // console.log(e.getExtData());
      if (e.getExtData().hasContent) {
        this.createInfoWindow(e.getExtData().pioData);
        this.activePoiData = e.getExtData().pioData;
      } else {
        this.navInfoFun(e.getExtData().pioData);
      }
      // console.log(this.activePoiData);
    },
    // //添加点聚合
    // addCluster() {
    //   let that = this;
    //   this.cluster = new AMap.MarkerClusterer(this.mapObj, this.markers, {
    //     gridSize: 80,
    //     renderClusterMarker: that._renderClusterMarker,
    //     zoomOnClick: true,
    //   });
    // },
    // //点聚合的样式
    // _renderClusterMarker(context) {
    //   context.marker.setOffset(new AMap.Pixel(-20, -20));
    //   context.marker.setContent(`<div class="contain">${context.count}</div>`);
    // },
    // 关闭信息窗口
    qureyData() {
      if (this.infoWindow) {
        this.mapObj.remove(this.infoWindow);
        this.infoWindow = null;
        this.activePoiData = "";
      }
    },
    //点击景点类型切换
    changePlace(index) {
      this.changeMark = index;
      this.tabActive = index;
      this.markerArr = this.initData[index].webPoiVos;
      this.markerArrIcon = this.initData[index];
      this.markers = [];
      this.createMarker();
      // this.addCluster();
      this.initMap();
    },
    //切换语言
    languageNumFn(index, languageType) {
      this.languageTagData = false;
      this.languageNum = index;
      this.languageType = languageType;
      this.getAllLineData();
      this.getSearchPoiData();
      this.getMarkerDetailData();
      this.getSenicData();
      this.lanData = this.manyLanData[this.languageType - 1];
    },
    // 点击路线
    pathShowFn() {
      this.qureyData();
      this.pathShow = true;
      if (this.navigationShow == true) {
        this.navigationShow = false;
      }
    },
    //点击显示路线导航
    navigationShowFn(index) {
      this.lineId = this.newlines[index].id;
      lineEyeApi({
        lineId: this.lineId,
      }).then((res) => {
        console.log(res);
      });
      this.pathShow = false;
      this.navigationShow = true;
      this.tabNameFn(index);
      this.lineMarkerArrData = this.allLineSelectData.linePointVos;
      this.initMap();
      this.lineArrData(this.lineMarkerArrData);
    },
    //点击切换路线导航
    tabNameFn(index) {
      this.tabNameNum = index;
      this.allLinePointNum = -1;
      this.allLineSelectData = this.allLineData[index];
      this.pid = this.newlines[index].linePointVos[0].pointId;
      this.lineMarkerArrDatas = this.newlines[index].linePointVos;
      this.lineMarkerArrData = this.allLineSelectData.linePointVos;
      this.initMap();
      this.lineArrData(this.lineMarkerArrData);
      // console.log(this.lineMarkerArrData);
      this.getMarkerDetailData();
      this.changeMarkAudioPlay();
    },
    //线路的线的数据处理
    lineArrData(data) {
      var arr = [];
      for (var i in data) {
        var arr1 = [data[i].longitude, data[i].latitude];
        arr.push(arr1);
      }
      this.polineShow(arr);
    },
    //线路显示
    polineShow(arr) {
      // console.log(arr);
      this.polyline = new AMap.Polyline({
        path: arr,
        isOutline: true,
        outlineColor: "#ffeeff",
        borderWeight: 1,
        strokeColor: "#3366FF",
        strokeOpacity: 1,
        strokeWeight: 3,
        // 折线样式还支持 'dashed'
        strokeStyle: "solid",
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 5],
        lineJoin: "round",
        lineCap: "round",
        zIndex: 700,
      });
      this.polyline.setMap(this.mapObj);
      // 缩放地图到合适的视野级别
      this.mapObj.setFitView([this.polyline]);
    },
    //切换语言
    changeLanguage() {
      if (this.languageTagData == false) {
        this.languageTagData = true;
      } else {
        this.languageTagData = false;
      }
    },
    toVr(e) {
      window.location.href = e;
    },
    //点击路线导航中的marker
    allLinePointFn(index, id) {
      this.allLinePointNum = index;
      this.pid = id;
      this.getMarkerDetailData();
      this.changeMarkAudioPlay();
      let marker = this.allLineData[this.tabNameNum].lineMarkerArr[index];
      // console.log(marker);
      this.markerLineClick(marker);
    },
    //景区路线
    getAllLineData() {
      allLineApi({
        languageType: this.languageType,
        scenicId: this.scenicId,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.allLineData = res.data.data;
          this.newlines = JSON.parse(JSON.stringify(this.allLineData));
          for (var j in this.allLineData) {
            this.newlines[j].linePointVos = [];
            for (var i in this.allLineData[j].linePointVos) {
              if (
                typeof this.allLineData[j].linePointVos[i].pointId !=
                "undefined"
              ) {
                this.newlines[j].linePointVos.push(
                  this.allLineData[j].linePointVos[i]
                );
              }
            }
          }
          // console.log(this.newlines);
        }
      });
    },
    //显示搜索页面
    showSearchPageFn() {
      this.showSearchPage = true;
      this.searchShow = false;
      this.qureyData();
    },
    //搜索
    onSearch() {
      Indicator.open(); //加载中
      const value = this.keyword.trim();
      if (!value) {
        //判断输入框的值是否为空  如果为空 直接返回不在执行
        this.searchShow = false;
        this.histroyContentShow = true;
        this.noResultShow = false;
      }
      //如果local里不存在当前输入的关键词 则存到local里
      if (this.searchList.indexOf(value) === -1) {
        this.searchList.unshift(value);
        //历史记录最多只取十个
        this.searchList = this.searchList.slice(0, 10);
        localStorage.setItem("keyword", JSON.stringify(this.searchList));
      }
      this.getSearchPoiData(); //获取搜索的内容
    },
    //取消
    onCancle() {
      this.showSearchPage = false;
      this.$router.go(0);
    },
    // 清楚历史记录
    clearHistroy() {
      MessageBox({
        $type: "confirm",
        message: "是否确定清除历史记录?",
        title: "提示",
        showCancelButton: true,
      })
        .then((res) => {
          console.log(res);
          if (res == "confirm") {
            localStorage.removeItem("keyword"); // 清除本地存储的数据
            this.searchList = []; //data中的historyList 清除
          }
        })
        .catch((err) => {
          return;
        });
    },
    //请求全域搜索的数据
    getSearchPoiData() {
      Indicator.open();
      searchPoiApi({
        languageType: this.languageType,
        name: this.keyword,
        scenicId: this.scenicId,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.searchData = res.data.data;
          // console.log(this.searchData);
          if (this.searchData.length == 0) {
            this.noResultShow = true;
            this.searchShow = false;
            this.histroyContentShow = false;
          } else {
            this.noResultShow = false;
            this.searchShow = true;
          }
        }
        Indicator.close(); //加载完毕
      });
    },
    //点击历史搜索，跳转搜索结果页
    goSearchDetail(title) {
      this.keyword = title;
      this.onSearch();
    },
    //返回上一页打开相对应的景点
    backPrev(id) {
      this.pid = id;
      this.getMarkerDetailData();
      this.showSearchPage = false;
      this.showDetailPage = true;
    },
    //详情页的显示事件
    showDetailPageFn() {
      this.showDetailPage = false;
      this.changeMarkAudioPlay();
    },
    showDetialpage(id) {
      this.showDetailPage = true;
      this.pid = id;
      this.getMarkerDetailData();
    },
    navigationFn() {
      this.navigationShow = false;
      this.initMap();
    },
    // 点击进度条跳到指定点播放
    // PS：此处不要用click，否则下面的拖动进度点事件有可能在此处触发，此时e.offsetX的值非常小，会导致进度条弹回开始处
    progressBarBg(e) {
      let audio = document.getElementById("audioPlayer");
      if (!audio.paused || audio.currentTime != 0) {
        // let pgsWidth = $(".progress-bar-bg").width();
        let pgsWidth =
          document.getElementsByClassName("progress-bar-bg")[0].offsetWidth;
        let rate = e.offsetX / pgsWidth;
        audio.currentTime = audio.duration * rate;
        this.updateProgress(audio);
      }
    },
    // 鼠标拖动进度点时可以调节进度
    // 只有音乐开始播放后才可以调节，已经播放过但暂停了的也可以
    // 鼠标按下时
    progressDot(e) {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      let dot = document.getElementById("progressDot");
      if (!audio.paused || audio.currentTime != 0) {
        let oriLeft = dot.offsetLeft;
        let mouseX = e.clientX;
        let maxLeft = oriLeft; // 向左最大可拖动距离
        let maxRight =
          document.getElementById("progressBarBg").offsetWidth - oriLeft; // 向右最大可拖动距离

        // 禁止默认的选中事件（避免鼠标拖拽进度点的时候选中文字）
        if (e.preventDefault) {
          e.preventDefault();
        } else {
          e.returnValue = false;
        }

        // 禁止事件冒泡
        if (e && e.stopPropagation) {
          e.stopPropagation();
        } else {
          window.event.cancelBubble = true;
        }

        // 开始拖动
        document.onmousemove = function (e) {
          let length = e.clientX - mouseX;
          if (length > maxRight) {
            length = maxRight;
          } else if (length < -maxLeft) {
            length = -maxLeft;
          }
          let pgsWidth =
            document.getElementsByClassName("progress-bar-bg")[0].width;
          let rate = (oriLeft + length) / pgsWidth;
          audio.currentTime = audio.duration * rate;
          that.updateProgress(audio);
        };

        // 拖动结束
        document.onmouseup = function () {
          document.onmousemove = null;
          document.onmouseup = null;
        };
      }
    },
    // 音频播放停止事件
    audioPlay() {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      // 监听音频播放时间并更新进度条
      audio.addEventListener(
        "timeupdate",
        function () {
          that.updateProgress(audio);
        },
        false
      );

      // 监听播放完成事件
      audio.addEventListener(
        "ended",
        function () {
          that.audioEnded();
        },
        false
      );

      // 改变播放/暂停图片
      if (audio.paused) {
        // 开始播放当前点击的音频
        audio.play();
        that.playing = true;
      } else {
        audio.pause();
        that.playing = false;
      }
    },
    // 切换marker音频停止事件
    changeMarkAudioPlay() {
      let audio = document.getElementById("audioPlayer");
      // 改变播放/暂停图片
      if (audio.paused) {
        this.audioEnded();
        this.audioInit();
      } else {
        audio.pause();
        this.audioEnded();
        this.audioInit();
      }
    },
    // 更新进度条与当前播放时间 audio - audio对象
    updateProgress(audio) {
      let value = audio.currentTime / audio.duration;
      this.barWidth = value * 100;
      this.dotLeft = value * 100;
      this.audioCurTime = this.transTime(audio.currentTime);
      this.duration = this.transTime(audio.duration);
    },
    /**
     * 音频播放时间换算
     * @param {number} value - 音频当前播放时间，单位秒
     */
    transTime(value) {
      let time = "";
      let h = parseInt(value / 3600);
      value %= 3600;
      let m = parseInt(value / 60);
      let s = parseInt(value % 60);
      if (h > 0) {
        time = this.formatTime(h + ":" + m + ":" + s);
      } else {
        time = this.formatTime(m + ":" + s);
      }

      return time;
    },
    /**
     * 格式化时间显示，补零对齐
     * eg：2:4  -->  02:04
     * @param {string} value - 形如 h:m:s 的字符串
     */
    formatTime(value) {
      let time = "";
      let s = value.split(":");
      let i = 0;
      for (; i < s.length - 1; i++) {
        time += s[i].length == 1 ? "0" + s[i] : s[i];
        time += ":";
      }
      time += s[i].length == 1 ? "0" + s[i] : s[i];

      return time;
    },
    //  播放完成时把进度调回开始的位置
    audioEnded() {
      this.barWidth = 0;
      this.dotLeft = 0;
      this.playing = false;
      this.audioCurTime = "00:00";
    },
    audioInit() {
      let that = this;
      let audio = document.getElementById("audioPlayer");
      if (this.audioSrc != "") {
        audio.load();
        audio.addEventListener("canplay", function () {
          //监听audio是否加载完毕，如果加载完毕，则读取audio播放时间
          that.duration = that.transTime(audio.duration);
        });
      }
    },
    //景区相应的数据
    getSenicData() {
      Indicator.open();
      scenicDataApi({
        languageType: this.languageType,
        scenicId: this.scenicId,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.initData = res.data.data.webSelectByPoiVos;
          console.log(this.initData);
          this.scenicData = res.data.data.webScenicVos[0];
          this.markerArr = this.initData[0].webPoiVos;
          this.markerArrIcon = this.initData[0];
          this.createMarker();
          this.initMap();
          // console.log(this.markerArrIcon);
          this.lanData = this.manyLanData[this.languageType - 1];
        }
        Indicator.close();
      });
    },
    //marker详情页数据
    getMarkerDetailData() {
      Indicator.open();
      markerDetailApi({
        languageType: this.languageType,
        pid: this.pid,
      }).then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.markerDetailData = res.data.data;
        }
        Indicator.close();
      });
    },
    //语言
    getLanguageData() {
      languageDataApi().then((res) => {
        // console.log(res);
        if (res.status == "200") {
          this.languageData = res.data.data;
          // console.log(this.languageData);
        }
      });
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 获取主页传递过来的id
    this.scenicId = this.$route.params.id;
    this.getSenicData(); //景区相应数据
    this.getAllLineData(); //景区路线
    this.getLanguageData(); //语言
    window.toNavigationFn = this.toNavigationFn;
    this.searchList = JSON.parse(localStorage.getItem("keyword")) || []; //先读取local里存储的历史记录
  },
};
</script>
<style lang="less">
.index {
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;

  //地图
  #container {
    width: 100%;
    height: 100%;
    position: relative;

    // 去除地图logo
    .amap-logo {
      display: none !important;
    }

    .amap-copyright {
      opacity: 0;
    }
    .curMarker {
      width: 48px;
      height: 54px;
      background: url("./image/location.svg") no-repeat;
      background-size: 100%;
    }
    //marker的样式
    .contain {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/spot_route.svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #000;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }

    .contain-active {
      width: 4rem;
      height: 4.5rem;
      background: url("./image/spot_route(1).svg") no-repeat;
      background-size: 100%;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
    }

    //marker的文本内容
    .amap-marker-label {
      position: absolute;
      z-index: 2;
      // border: 1px solid white;
      // border-radius: 2.1rem;
      // background-color: white;
      // opacity: 0.96;
      border: 0;
      background: none;
      white-space: nowrap;
      font-weight: 600;
      cursor: default;
      padding: 3px 7px;
      font-size: 1.4rem;
      text-shadow: 2px 2px 2px #fff;
    }

    .nav-info {
      justify-content: space-between;
      background: #fff;
      // width: 150px;
      box-shadow: 0rem 4px 4px 0rem rgba(85, 64, 22, 0.1);
      border-radius: 5px;
      padding: 8px 7px;
      // height: 56px;
      position: relative;

      .info-box {
        width: 70px;
        height: 28px;
        border-radius: 15px;
        line-height: 28px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #43525c;
        letter-spacing: 0rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-hear {
          width: 1.8rem;
          height: 1.8rem;
          display: inline-block;
          opacity: 1;
          background: url("./image/icon_nav.svg") no-repeat;
          background-size: 100%;
          margin-right: 3px;
        }
      }

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-width: 8px 8px 0;
        border-style: solid;
        border-color: #fff transparent transparent;
        position: absolute;
        // bottom: -0px;
        bottom: -8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 104;
      }
    }
  }

  //左侧手绘开关
  .leftSwitch {
    width: 4.8rem;
    height: 4.8rem;
    background: url("./image/button_classification.svg") no-repeat;
    background-size: 100%;
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .switch {
      width: 3rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .mint-switch {
        width: 100%;
        height: 100%;
        margin: 0;

        .mint-switch-core {
          width: 100%;
          height: 100%;
        }

        .mint-switch-input:checked + .mint-switch-core {
          background: #5a6570;
        }

        .mint-switch-core::before {
          width: 100%;
          height: 100%;
        }

        .mint-switch-core::after {
          width: 35%;
          height: 100%;
        }
      }
    }

    .leftTitel {
      width: 100%;
      height: 1.8rem;
      overflow: hidden;
      text-align: center;
      font-size: 0.9rem;
      font-weight: 400;
      text-align: CENTER;
      color: #5a6570;
      line-height: 1.8rem;
      padding: 0 0.4rem;
    }
  }

  // 地点选择
  .placeSelect {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    width: 4.8rem;
    height: 27.2rem;
    position: fixed;
    top: 6.9rem;
    left: 1.5rem;
    z-index: 1000;
    background: url("./image/Group 595.svg") no-repeat;
    background-size: 100%;

    .places {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 4.8rem;
      height: 4.8rem;
      margin: 0.2rem auto;

      .placeUrl {
        width: 2.4rem;
        height: 2.4rem;
        background: #fff;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .placeName,
      .placeNames {
        width: 4rem;
        text-align: center;
        font-size: 1rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #3b444d;
        line-height: 1.4rem;
        white-space: nowrap;
        overflow: hidden;
      }

      .placeNames {
        color: #000;
        font-weight: 400;
      }
    }
  }

  //右侧选择
  .rightSelect {
    width: 4.8rem;
    height: 16.4rem;
    position: fixed;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1000;

    .rightContent {
      width: 4.8rem;
      height: 4.8rem;
      background: url("./image/button_classification.svg") no-repeat;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.6rem;

      .rightIcon {
        width: 2rem;
        height: 2rem;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rightTitel {
        width: 100%;
        height: 1.4rem;
        padding: 0 0.4rem;
        text-align: center;
        font-size: 1rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: CENTER;
        color: #3b444d;
        line-height: 1.4rem;
        overflow: hidden;
      }
    }
  }

  // 右侧语言选择
  .rightSelected {
    // width: 4.8rem;
    height: 3.8rem;
    position: absolute;
    right: 0;
    top: 44rem;
    background: #fff;
    border-radius: 4rem 0 0 4rem;
    overflow: hidden;
    display: flex;
    align-items: center;

    .allShow {
      display: flex;
      align-items: center;

      .selectLanguage {
        width: 3.2rem;
        height: 3.2rem;
        background: #5c7190;
        border-radius: 50%;
        margin: 0 0.5rem 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .selectIcon {
          width: 2.4rem;
          height: 2.44rem;
          border-radius: 50%;
          background: url("./image/globel.svg") no-repeat 100%;
          background-size: 100%;
        }
      }
    }

    .languageSelect {
      width: 10.3rem;
      height: 3.8rem;
      background: #fff;
      margin: 0 0.9rem 0 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .selectContent {
        width: 25%;
        height: 1.7rem;
        text-align: center;
        line-height: 1.7rem;
        border-right: 0.1rem solid #eee;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Regular;
      }

      .selectContent-active {
        width: 25%;
        height: 1.7rem;
        text-align: center;
        color: #5c7190;
        line-height: 1.7rem;
        border-right: 0.1rem solid #eee;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: PingFang SC, PingFang SC-Regular;
      }
    }
  }

  // 路线
  .path {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .pathContent {
      width: 32.3rem;
      height: 56rem;
      position: absolute;
      top: 50%;
      margin-top: -28rem;
      background: #f8f8f8;
      border-radius: 0.8rem;

      .photoTitle {
        width: 100%;
        height: 7.4rem;
        background: url("./image/Group 589.svg") no-repeat;
        background-size: 100%;
      }

      .closePath {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        background: url("./image/icon_window_close.svg") no-repeat;
        background-size: 100%;
        position: absolute;
        bottom: -5rem;
        right: 50%;
        margin-right: -2.4rem;
      }

      .lineContent {
        width: 100%;
        height: 48rem;
        overflow: scroll;
        padding: 1.6rem 1.4rem 0;

        .contentTitle {
          font-size: 1.8rem;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: LEFT;
          color: #454e55;
          padding: 0 0.2rem;
          letter-spacing: 0rem;

          .tox {
            width: 2.4rem;
            height: 2.4rem;
            background: url("./image/tox.svg") no-repeat;
            background-size: 100%;
            float: right;
          }
        }

        .contentLine {
          width: 100%;
          float: right;
          padding: 1.2rem;
          margin: 1.2rem 0 1.6rem;
          background: #ffffff;
          border-radius: 0.8rem;

          .lineTitle {
            font-size: 1.2rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
          }

          .line {
            width: 100%;

            .detailLine {
              // width: 13rem;
              // height: 2.5rem;
              display: inline-block;
              position: relative;

              .linePoint {
                font-size: 1.4rem;
                font-family: PingFang SC, PingFang SC-Medium;
                font-weight: 500;
                text-align: LEFT;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;

                .to {
                  width: 2rem;
                  height: 2rem;
                  display: inline-block;
                  background: url("./image/to.svg") no-repeat;
                  background-size: 100%;
                  margin: 0 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }

  //路线导航
  .lineNavigation {
    width: 100%;
    height: 24.2rem;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #f5f5f5;
    padding: 1.5rem 0 0 0.6rem;
    z-index: 1010;

    .navigationTab {
      width: 34rem;
      height: 3rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;

      .closeNavigation {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background: url("./image/icon_window_close(1).svg") no-repeat;
        background-size: 100%;
        position: absolute;
        top: 1.2rem;
        right: 0.7rem;
      }

      .tabName {
        height: 2.5rem;
        line-height: 2.5rem;
        display: inline-block;
        font-size: 1.4rem;
        padding: 0 1rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #999999;
      }

      .tabNameActive {
        color: #454e55;
        border-bottom: 0.15rem solid #454e55;
      }
    }

    // 详情展示内容
    .navigationWindow {
      width: 100%;
      height: 10.8rem;
      padding: 1.5rem 0.6rem;

      .windowImg {
        width: 7.8rem;
        height: 7.8rem;
        display: flex;
        border-radius: 0.4rem;
        justify-content: center;
        align-items: center;
        background: cornflowerblue;
        float: left;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }

        .icon_audio,
        .icon_audio-active {
          width: 2.4rem;
          height: 2.4rem;
          border-radius: 50%;
          background: url("./image/icon_play.svg") no-repeat;
          background-size: 100%;
          position: absolute;
          // left: 50%;
          // margin-left: -1.2rem;
          // top: 50%;
          // margin-top: -1.2rem;
          z-index: 4;
        }

        .icon_audio-active {
          background: url("./image/icon_suspend.svg") no-repeat;
          background-size: 100%;
        }
      }

      .windowContent {
        width: 20rem;
        height: 100%;
        display: inline-block;
        padding-left: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .windowTitle {
          height: 2rem;
          line-height: 2rem;
          font-size: 1.8rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: LEFT;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }

        .WindowBtn {
          width: 100%;
          height: 2.8rem;

          .navigationBtn1,
          .navigationBtn2 {
            width: 6.8rem;
            height: 2.8rem;
            line-height: 2.8rem;
            display: inline-block;
            font-size: 1.4rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #fff;
            text-align: center;
            margin-right: 1.2rem;
            background: url("./image/Union1.svg") no-repeat;
            background-size: 100%;
          }

          .navigationBtn2 {
            background: url("./image/Union2.svg") no-repeat;
            background-size: 100%;
          }
        }
      }

      //点击选择路线
      .line-bottom {
        .line-pois {
          width: 100%;
          height: 10rem;
          overflow-x: scroll;
          overflow-y: hidden;
          white-space: nowrap;
          padding-top: 2rem;

          .line-poi-item {
            position: relative;
            width: 10.4rem;
            height: 100%;
            // margin-right: 50px;
            display: inline-block;

            .line-item-index {
              margin: 0 auto;
              width: 3.2rem;
              height: 3.7rem;
              font-size: 1.4rem;
              font-stretch: normal;
              font-weight: 700;
              line-height: 3rem;
              letter-spacing: 0px;
              color: #5a6570;
              text-align: center;
              background: url("./image/spot_route.svg") no-repeat;
              background-size: 100%;
            }

            .line-item-index-active {
              margin: 0 auto;
              width: 3.2rem;
              height: 3.7rem;
              font-size: 1.4rem;
              font-stretch: normal;
              font-weight: 700;
              line-height: 3rem;
              letter-spacing: 0px;
              color: #fff;
              text-align: center;
              background: url("./image/spot_route(1).svg") no-repeat;
              background-size: 100%;
            }

            .line-item-name {
              margin-top: 0.8rem;
              line-height: 1.7rem;
              font-size: 1.2rem;
              font-weight: 500;
              text-align: center;
              color: #999999;
              letter-spacing: 0rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }

            .line-item-name-active {
              color: #5a6570;
            }
          }

          .line-poi-item:last-child {
            margin-right: 0;
          }

          .line-poi-item:first-child {
            margin-left: 0.8rem;
          }

          .line-poi-item:not(:last-child)::after {
            position: absolute;
            content: "";
            width: 6rem;
            height: 0.2rem;
            border-bottom: 0.2rem dashed #5a6570;
            right: -3.2rem;
            top: 1rem;
            z-index: -1;
          }

          .line-poi-item.on {
            .line-item-index {
              // background: url("./images/Group_on.svg") no-repeat;
              color: #fff;
            }

            .line-item-name {
              font-size: 1.2rem;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: CENTER;
              color: #999999;
            }
          }
        }
      }
    }
  }

  //搜索页面
  .search {
    width: 100%;
    height: 100vh;
    padding: 0.9rem 1.6rem 0;
    position: fixed;
    background: #fff;
    z-index: 1001;

    //搜索框
    .search-box {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      position: relative;

      input {
        width: 29.5rem;
        height: 4.4rem;
        background: #fafafa;
        border-radius: 4.4rem;
        padding-left: 3rem;
      }

      .serchbtn {
        width: 1.7rem;
        height: 1.7rem;
        background: url("./image/icon_search.svg") no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0.7rem;
        left: 1rem;
      }

      .onCancle {
        width: 4rem;
        height: 3rem;
        font-size: 1.6rem;
        border-radius: 3rem;
        font-weight: 500;
        text-align: center;
        line-height: 3rem;
        color: #43525c;
      }
    }

    //搜索内容
    .searchContent {
      width: 100%;
      height: 100%;
      background: #fff;
      position: fixed;
      top: 7rem;

      .sContent {
        width: 85%;
        height: 5.6rem;
        border-bottom: 1px solid #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .scenicItem {
          width: 100%;
          height: 3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .leftItem {
            display: flex;

            .itemIcon {
              width: 2.2rem;
              height: 2.2rem;
              margin-right: 0.5rem;
            }

            .itemName {
              height: 2.2rem;
              line-height: 2.2rem;
              font-size: 1.6rem;
              color: #454e55;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
            }
          }
        }
      }
    }

    .noResult {
      width: 100%;
      height: 100%;
      line-height: 50%;
      height: 3rem;
      margin-left: 7rem;
      position: absolute;
      top: 15rem;
      z-index: 444;
    }

    //历史框
    .histroy {
      width: 100%;
      height: 3rem;
      margin: 3rem 0 1.3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .leftName {
        font-size: 1.6rem;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #444444;
      }

      .icon_del {
        width: 2.8rem;
        height: 2.8rem;
        background: url("./image/button_delete.svg") no-repeat;
        background-size: 100%;
      }
    }

    //历史记录内容
    .histroyContent {
      width: 100%;
      height: 5.6rem;

      .histroyList {
        height: 5.6rem;
        padding: 1.7rem 0;
        display: flex;
        align-items: center;

        .areaName {
          height: 2.2rem;
          font-size: 1.6rem;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #454e55;
          line-height: 2.2rem;
          letter-spacing: 0rem;
        }
      }
    }
  }

  //详情页面
  .visitorsDetail {
    width: 100%;
    height: 100vh;
    background: #fff;
    position: fixed;
    z-index: 1001;

    // 景点介绍卡片
    .introduceCard {
      width: 100%;
      height: 28.9rem;
      position: relative;

      .swiper {
        width: 100%;
        height: 22.5rem;
        position: relative;

        .mint-swipe-item {
          background: #fff;
        }

        .mint-swipe-indicators {
          position: absolute;
          bottom: 2.5rem;
          left: 50%;
          transform: translateX(-50%);
        }

        .vr {
          width: 6.1rem;
          height: 2.6rem;
          opacity: 0.9;
          background: #ffffff;
          border-radius: 2.1rem;
          position: absolute;
          bottom: 4rem;
          right: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.3rem;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          text-align: CENTER;
          color: #302e28;
          line-height: 1.3rem;
          letter-spacing: 0rem;

          .icoon {
            width: 1.8rem;
            height: 1.8rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .introduceText {
        width: 100%;
        height: 8.8rem;
        background: #fff;
        position: absolute;
        top: 20rem;
        border-radius: 0.8rem 0.8rem 0 0;
        box-shadow: 0rem 0.1rem 0.8rem 0rem rgba(0, 0, 0, 0.06);
        padding: 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .introduceTitle {
          width: 100%;
          height: 2rem;
          display: flex;
          align-items: center;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;

          .titleName {
            height: 1.8rem;
            line-height: 1.8rem;
            font-size: 1.8rem;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
          }
        }

        .introduceAddress {
          width: 32rem;
          height: 2rem;
          display: flex;
          justify-items: center;

          .icon-Address {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background: url("./image/icon_address.svg") no-repeat;
            background-size: 100%;
            flex-shrink: 0;
          }

          .addressText {
            height: 2rem;
            line-height: 2rem;
            font-size: 1.3rem;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
        }

        .introduceBtn {
          width: 2.4rem;
          height: 2.4rem;
          position: absolute;
          top: 5rem;
          right: 1.6rem;
          background: url("./image/icon_nav.svg") no-repeat;
          background-size: 100%;
        }
      }
    }

    //中间介绍
    .centerIntroduce {
      width: 100%;
      height: 40rem;
      padding: 1.6rem;
      background: url("./image/bg.svg") no-repeat 0 16rem;
      background-size: 100%;

      .intro {
        width: 30rem;
        height: 5.2rem;
        background: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 1.6rem;
      }

      .introText {
        width: 100%;
        height: 26rem;
        font-size: 1.4rem;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
        line-height: 2.6rem;
        letter-spacing: 0rem;
        overflow: auto;
      }
    }

    //底部返回
    .buttomBack {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      position: fixed;
      bottom: 1.6rem;
      right: 1.6rem;
      background: url("./image/button_back.svg") no-repeat;
      background-size: 100%;
    }
  }

  //音频组件
  .audio {
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    .icon_play {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      background: #f85f5f;
    }

    .audio-box {
      width: 28rem;
      height: 4.8rem;

      .player-box {
        width: 100%;
        height: 4.8rem;
        justify-content: space-between;
        padding: 0 2.8rem 0 0.8rem;
        // margin: 0.4rem auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .audio-left {
          /* float: left; */
          margin-right: 0.4rem;
          width: 3.2rem;
          height: 3.2rem;
          background: #5a6570;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            width: 1.6rem;
            height: 1.6rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        #audioPlayer {
          cursor: pointer;
        }

        .audio-right {
          flex: 1;
          width: 0;
          height: 100%;
          justify-content: center;
          padding-left: 0.5rem;

          .progress-bar-bg {
            width: 100%;
            background: #eee;
            position: relative;
            height: 0.2rem;
            cursor: pointer;
            border-radius: 0.2rem;
            margin-bottom: 0.6rem;
            margin-top: 2rem;

            span {
              content: " ";
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              background-color: #fff;
              border: 0.1rem solid #999999;
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -0.5rem;
              margin-left: -0.5rem;
              cursor: pointer;
              z-index: 2;
            }

            .progress-bar {
              background: #5a6570;
              width: 0;
              height: 0.2rem;
              // margin-top: -0.01rem;
              border-radius: 0.2rem;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              z-index: 1;
            }
          }

          .audio-time {
            width: 100%;
            height: 1.7rem;
            overflow: hidden;
            color: #999999;
            justify-content: flex-start;
            // margin-top: 0.16rem;
            line-height: 1.7rem;
            font-size: 1.2rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
  .leftLocation {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.6rem;
    opacity: 0.96;
    background: #ffffff;
    border-radius: 50%;
    position: absolute;
    bottom: 12rem;
    left: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    img {
      width: 2.2rem;
      height: 2.2rem;
    }
  }
}
</style>