<!-- 信息窗体 -->
<template>
  <div class="infoWindow">
    <audio :src="$global.ImgUrl + extData.audio" ref="audioPlay"></audio>
    <div class="windowTitle ellipsis-1">{{ extData.name }}</div>
    <div class="closeWindow" @click="closeInfoWindow"></div>
    <div class="windowContent">
      <div class="leftImg" @click="audioPlay()">
        <img :src="$global.ImgUrl + extData.cover" alt="" />
        <div v-show="extData.audio">
          <div class="audioIcon" v-if="playing"></div>
          <div class="audioIcon-active" v-else></div>
        </div>
      </div>
      <div class="rightNav">
        <div class="detailBtn" @click="openDetailFn(extData.id)">
          <span class="detaiIcon"></span> <span>详情</span>
        </div>
        <div class="navBtn">
          <span class="navIcon"></span>
          <span @click="toNavigationFn(extData)">导航</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      playing: false,
      resLocation: [], //自身定位
    };
  },
  props: {
    extData: {
      type: String,
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    extData(newVal, oldVal) {
      if (newVal) {
        this.playing = false;
      }
    },
  },
  //方法集合
  methods: {
    //关闭窗口
    closeInfoWindow() {
      this.$emit("infoClose");
    },
    //显示详情页
    openDetailFn(id) {
      let audio = this.$refs.audioPlay;
      audio.pause();
      this.playing = false;
      this.$emit("showDetail", id);
    },
    //跳转到导航
    toNavigationFn(item) {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //在微信就用微信地图
        this.commonFun.openLocation({
          name: item.name, // 位置名
          latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
          longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
          address: item.address, // 地址详情说明
          scale: 12, // 地图缩放级别,整形值,范围从1~28。默认为最大
          infoUrl: "", // 在查看位置界面底部显示的超链接,可点击跳转
        });
      }
    },
    //全局音频的播放暂停
    audioPlay() {
      let audio = this.$refs.audioPlay;
      // 改变播放/暂停图片
      if (audio.paused) {
        audio.play();
        this.playing = true;
      } else {
        audio.pause();
        this.playing = false;
      }
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="less">
.infoWindow {
  width: 18rem;
  height: 11rem;
  padding: 0.8rem;
  background: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 999;

  //窗体的标题
  .windowTitle {
    width: 90%;
    height: 2rem;
    font-size: 1.4rem;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #454e55;
    line-height: 2rem;
    letter-spacing: 0rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  //关闭窗体
  .closeWindow {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: url("./image/icon_window_close(1).svg") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
  }

  //窗体内容
  .windowContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .leftImg {
      width: 8rem;
      height: 6rem;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      .audioIcon,
      .audioIcon-active {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1.3rem;
        margin-top: -1.3rem;
        background: url("./image/icon_suspend.svg") no-repeat;
        background-size: 100%;
      }

      .audioIcon-active {
        background: url("./image/icon_play.svg") no-repeat;
        background-size: 100%;
      }
    }

    .rightNav {
      width: 7.2rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .detailBtn,
      .navBtn {
        width: 100%;
        height: 2.4rem;
        background: #9a786d;
        border-radius: 1.5rem;
        text-align: center;
        line-height: 2.4rem;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: CENTER;
        color: #ffffff;
        letter-spacing: 0rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .detaiIcon {
          width: 1.6rem;
          height: 1.6rem;
          display: inline-block;
          background: url("./image/icon_detail.svg") no-repeat;
          background-size: 100%;
        }
      }

      .navBtn {
        background: #5b74a7;

        .navIcon {
          width: 1.6rem;
          height: 1.6rem;
          display: inline-block;
          background: url("./image/navx.svg") no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}
</style>